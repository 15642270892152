import './App.css';
import React from 'react';
import Container from '@material-ui/core/Container';
import { Button, Typography } from '@material-ui/core';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Grid } from '@material-ui/core';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Grid container direction="column" xs={12} alignItems="center">
          <Grid item className="App-banner">
            <Typography variant="h1">{'Alexander Arendttorp'}</Typography>
            <Typography variant="p">
              {'site under construction'}
            </Typography>
          </Grid>
          <Grid style={{ height: 50 }}></Grid>
          <Grid container direction="row" justify="center" alignContent="center" xs={2} >
            <Button variant="text" color="white" href="https://www.linkedin.com/in/alexanderarendttorp/"><LinkedInIcon style={{ fontSize: 50, color: '#ffffff' }} /></Button>
            <Button variant="text" color="white" href="https://twitter.com/a_arendttorp"><TwitterIcon style={{ fontSize: 50, color: '#ffffff' }} /></Button>
          </Grid>
        </Grid>
      </header>
    </div>
  );
}

export default App;
